@import '~wolox-equalizer/equalizer';
@import '~react-datepicker/dist/react-datepicker.css';

@import 'layout';
@import 'margins';
@import 'components';
@import 'base';
@import 'custom';
@import 'buttons';

@font-face {
  font-family: 'Joey';
  src: local('FS_Joey'), local('Joey'),
    url('../fonts/FS_Joey.otf') format('opentype');
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../fonts/barlow.ttf');
}

@font-face {
  font-family: 'Covered';
  font-style: normal;
  font-weight: 400;
  src: local('Covered'),
    url('../fonts/CoveredByYourGrace.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  src: local('Barlow'), url('../fonts/barlow.ttf');
}

* {
  font-family: 'Barlow';
  src: local('Barlow'), url('../fonts/barlow.ttf');
}

.ReactModal__Body--open {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.ReactModalPortal {
  :focus {
    outline: 0;
  }
}

strong {
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
