@import 'src/scss/colors';
@import 'src/scss/margins';

.container {
  width: 100%;
  background-color: #f2f2f2;
  height: 100%;
  border-top: 2px solid $primary;
}

.mainRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 75px;
  width: 100%;
  height: inherit;
}

.linksRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-decoration: underline;
  color: #4d4d4d;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .linksRow {
    a {
      font-size: 12px;
    }
  }

  .mainRow {
    height: auto;
    padding-left: 0;
  }

  .secondRow {
    text-align: center;
    font-size: 13px;
  }
}
