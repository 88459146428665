@import 'src/scss/colors';

.navbar {
  background-color: $primary-blue;
  justify-content: space-between;
  padding: 0 auto;
  height: 8vh;
  .logoSura {
    height: 30px;
    width: auto;
  }
}
