@import 'src/scss/base.scss';
@import 'src/scss/margins.scss';

.container {
  background-position: 2% 5%, 0 0, 0 0, 0 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  min-height: 86vh;
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  position: relative;
  // width: 100%;
  // display: table;
  display: flex;
}

.containerSection {
  width: 100%;
}
.section {
  max-width: calc(100% - 200px);
  width: 85%;
  background-color: #fcfcfc;
  min-height: inherit;
  // display: table-cell;
}

.actions {
  position: fixed;
  top: 80px;
  height: 400px;
  width: 60px;
  overflow: hidden;
  right: 0;
  padding: 0;
  z-index: 88;
}

@media only screen and (max-width: $breakpoint-desktop-medium) {
  .container {
    background-size: 100px, cover, cover, contain;
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .actions {
    position: fixed;
    top: initial;
    height: initial;
    width: auto;
    overflow: hidden;
  }
  .section {
    max-width: calc(100% - 150px);
  }
}
