@import 'src/scss/colors';
@import 'src/scss/margins.scss';

.nav {
  min-width: 200px;
  width: 15%;
  background-color: #edf1f6;
  padding: 20px 20px;
  min-height: 86vh;
  // display: table-cell;

  .parent {
    color: $primary-blue;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;

    .title {
      color: $primary-blue;
      font-size: 24px;
      font-weight: 600;
    }

    .children {
      line-height: 18px;
      margin-left: 15px;
      margin-bottom: 4%;

      .link {
        color: #4d4d4d;
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-tablet) {
  .nav {
    min-width: 150px;
    padding: 10px 10px;
    .parent {
      .title {
        font-size: 18px;
      }
      .children {
        .link {
          font-size: 12px;
        }
      }
    }
  }
}
