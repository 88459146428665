@import 'src/scss/margins';

.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  height: 8vh;
  z-index: 66666;
  display: none;
}

.toolbarNavigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}

span .linkToHome {
  margin-top: 6px;
}

.logoSura {
  background-size: 90%, cover, cover, contain;
  height: 41px;
  width: 100px;
  margin-top: 0;
  margin-left: 8px;
  // background-image: url("/assets/img_logo_general.svg");
  background-image: url('../../../../../../public//assets/img_logo_general.svg');
  background-repeat: no-repeat;
  background-position-y: center;
}

.userIcon {
  margin-left: auto;
}

.userThumbnail {
  float: left;
  margin-right: 4px;
  border: 1px solid #afafaf;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  right: 10px;
}

.userThumbnail img {
  height: 20px;
  margin-top: -1px;
  margin-left: 1px;
}

@media (max-width: $breakpoint-tablet) {
  .toolbar {
    display: inline-block;
  }
}

@media (min-width: 769px) {
  .toolbarToggleButton {
    display: none;
  }
}
