@import 'src/scss/margins.scss';

.actions {
  position: fixed;
  top: 80px;
  height: 400px;
  width: 60px;
  overflow: hidden;
  right: 0;
  padding: 0;
  z-index: 88;
}

@media only screen and (max-width: $breakpoint-tablet) {
  .actions {
    position: fixed;
    top: initial;
    height: initial;
    width: auto;
    overflow: hidden;
  }
}
