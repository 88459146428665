@import 'src/scss/colors';

.dropdown {
  .header {
    line-height: 30px;
    font-size: 18px;
    border: none;
    min-width: 160px;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    &::after {
      display: none;
    }

    &:active,
    &:focus,
    &:hover {
      border: none !important;
      outline: 0;
      box-shadow: none !important;
      background-color: $primary-blue !important;
      border-color: $primary-blue !important;
    }

    // .user-thumbnail {
    .userThumbnail {
      margin-right: 10px;
      border: 1px solid #afafaf;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      width: 30px;
      height: 30px;
      text-align: center;

      img {
        height: 20px;
        margin-top: -6px;
      }
    }
  }

  .dropdownMenu {
    margin-top: 6px !important;
    right: 0;
    left: auto;
    background-color: #edf1f6;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    box-shadow: 0px 6px 12px #00000005;

    a {
      font-size: 16px;
      &:hover {
        background-color: #edf1f6;
        border-radius: 24px;
        color: $primary-blue;
        text-decoration: none;
      }
      &:focus {
        color: $primary-blue;
      }
      svg {
        margin-right: 8px;
      }
    }
  }
}
