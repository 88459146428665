$font-family: 'Barlow', sans-serif;
$text-extra-small: 10px;
$text-very-small: 12px;
$text-small-big: 13px;
$text-button: 14px;
$text-help-pasword: 15px;
$text-small: 18px;
$text-input-login: 17px;
$text-header: 18px;
$text-medium-black: 20px;
$text-nav: 22px;
$text-medium: 24px;
$text-title-medium: 30px;
$text-medium-big: 35px;
$text-big-black: 40px;
$text-big: 48px;
$text-title-number: 25px;
$text-title-number-big: 50px;

// world Component
$text-world-default: 18px;
$text-world-complete: 20px;
$text-world-active: 24px;

// media
$text-big-black-query: 36px;
$text-small-query: 14px;

//Max-width
$max-width-big: 990px;

//engine
$title-option: 30px;
$line-option: 33px;
