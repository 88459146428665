@import 'src/scss/colors';

.container {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999999;
}

.optionRow {
  height: 75px;
  background-color: #f0f0f0;
  width: 100%;
  padding: 4px 0px;
  box-shadow: 0px 2px 12px #00000029;
  position: fixed;
  bottom: 0;
}

.optionCol {
  text-align: center;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  //border-right: 0.5px solid rgba(112, 112, 112, 0.25);
  &:nth-last-child(1) {
    border-right: none;
  }
  span {
    line-height: 14px;
  }
}
.optionActive {
  span {
    color: $primary;
  }
}

.personalInfoRow,
.myResultsRow {
  flex-direction: row;
  justify-content: center;
  display: flex;
  height: 0;
  transition: height 0.3s ease-in-out;

  .subMenuCol {
    background-color: #ffffff;
    max-width: 80%;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 2px 12px #00000029;
  }

  .subMenuTitle {
    margin-top: 10px;
    color: $primary;
    font-size: 22px;
    padding: 10px;
    font-weight: bold;
  }
}

.personalInfoRow {
  &.active {
    height: 330px;
  }
}

.myResultsRow {
  height: 0;
  transition: height 0.3s ease-in-out;
  &.active {
    height: 240px;
  }
}

.subMenuOption {
  padding: 10px;
  border-bottom: 0.5px solid rgba(112, 112, 112, 0.25);
  a {
    font-size: 15px;
  }

  &:nth-last-child(1) {
    border-bottom: none;
  }
}
