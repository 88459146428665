$theme-colors: (
  'primary': #0432a0
);

@import '~bootstrap/scss/bootstrap';

.nav-tabs-custom .nav-link.active,
.nav-tabs-custom .nav-item.show .nav-link {
  color: #0033a0;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 2px solid #0033a0;
  font-weight: bolder;
}

.nav-tabs-custom {
  font-size: 22px;
  .nav-link {
    border-bottom: 1px solid #dee2e6;
  }
}
