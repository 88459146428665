.sideDrawer {
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  position: fixed;
  width: 85%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.logoSura {
  background-size: 90%, cover, cover, contain;
  height: 41px;
  width: 100px;
  margin-top: 0;
  margin-left: 8px;
  background-image: url('../../../../../../public/assets/img_logo_general.svg');
  // background-image: url('/assets/img_logo_general.svg');
  background-repeat: no-repeat;
  background-position-y: center;
}

.sideDrawer .userOption .option {
  border-bottom: 1px solid #e2e2e2 !important;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.sideDrawer .userOption .logout {
  padding: 0.8rem 1.5rem;
}

.separator {
  margin: 5px 0;
  border-top: 1px solid #989898;
}

.sideDrawer .navbarNav .dropdownMenu {
  position: static !important;
}

.sideDrawer.open {
  transform: translateX(0);
  z-index: 9999999;
}

.borderBox {
  border: 1px solid #ddd;
  -webkit-border-top-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  border-top-right-radius: 8px;
  height: 54px;
  padding: 6px;
  box-shadow: 5px 0 5px -2px #00000029;
}

.menuList {
  padding: 0 1rem;
  text-align: end;
}

.menuList li a,
.logout {
  color: #4d4d4d;
}

.menuList li a:hover,
.logout:hover {
  color: #0432a0;
}

.logout:hover {
  cursor: pointer;
}

.nickName {
  color: #0432a0;
  font-size: 15pt;
  font-weight: bold;
  line-height: 36px;
  margin: 0 14px;
}

.subtitle {
  font-size: 15pt;
}

.subtitle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.sideDrawer ul {
  list-style: none;
  display: inline-block;
  flex-direction: column;
  justify-content: center;
  min-width: 85%;
  padding: 0 1rem;
  max-height: calc(100% - 54px);
  overflow-y: scroll;
}

.sideDrawer li {
  margin: 0;
}

.sideDrawer li * {
  font-size: 1.1rem;
  width: 100%;
  color: #666666;
  line-height: 1.2;
}

.sideDrawer li a:hover,
.sideDrawer li a:focus,
.sideDrawer li a:active {
  color: black !important;
  background-color: white;
}

.dropdownMenu a {
  border: none !important;
}

.sideDrawer a,
.logout {
  text-decoration: none;
  font-size: 1.1rem;
}

.sideDrawer a:hover,
.sideDrawer a:active {
  color: #fa923f;
}

.helpUsIcon {
  height: 1.5rem;
  width: auto !important;
}

.sideDrawer .navbarLight .navbarNav .navLink,
.sideDrawer .menuList li a,
.sideDrawer .logout {
  color: #6f6f6f !important;
}

.sideDrawer .navbarLight .navbarNav .navLink:hover,
.sideDrawer .menuList li a:hover,
.sideDrawer .logout:hover {
  color: #000 !important;
}

.sideDrawer .menuList li .helpUsText {
  color: #1eb2a0 !important;
  font-size: 22px;
  padding: 1rem 1.5rem 1.5rem 0;
}

.sideDrawer .menuList li .helpUsText:hover {
  color: #1eb2a0 !important;
  font-weight: bold;
}

.mobileNavbar {
  padding: 0;

  .navbarNav {
    border: none !important;
    text-align: right;
    a {
      padding: 1rem 1.5rem !important;
      text-align: right;
      border-bottom: 1px solid #e2e2e2;
    }
    div {
      border: none;
      .dropDown {
        border-bottom: none !important;
      }
      div {
        :nth-last-child(1) {
          border-bottom: 1px solid #e2e2e2 !important;
        }
      }
    }
    :nth-last-child(1) {
      a {
        border-bottom: none !important;
      }
      div {
        :nth-last-child(1) {
          border-bottom: none !important;
        }
      }
    }
  }
}

.dropdownItemMenu {
  div {
    padding-top: 0;
  }
}

:global {
  .show {
    a {
      border-bottom: none !important;
    }
    ::after {
      transform: rotate(180deg);
    }
  }
}

@media (min-width: 769px) {
  .sideDrawer {
    display: none;
  }
}

@media (min-width: 360px) {
  .sideDrawer .menuList li .helpUsText {
    font-size: 20px;
  }
}
