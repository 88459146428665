%buttons {
  border-radius: 50px;
  color: $smalt;
  cursor: pointer;
  font-size: $text-button;
  font-weight: bold;
  height: 100%;
  max-height: 50px;
  max-width: 200px;
  padding: 20px;
  width: 100%;
}

.btn-primary:active,
.btn-primary:focus {
  outline: 0;
  border: none;
  box-shadow: none;
}

.dropdown-item {
  font-size: 20px;
  border-bottom: 1px solid #f5f5f5 !important;
  padding: 0.5rem 1.5rem !important;
}

.btn-red_circle {
  background-color: $trash-background;
  color: $trash;
  border-radius: 50%;
  font-size: $text-medium;
  width: 40px;
  height: 40px;
  padding: 0;
  justify-self: flex-start;
  &:hover {
    color: $trash;
  }
}

.btn-primary-pill {
  background-color: $primary;
  border-radius: 10rem;
  color: $white;
  padding: 7px 25px;
}

.btn-progress-purposes {
  background-color: transparent;
  padding: 0.375rem 0;
  color: $black;
}

.btn-expenses:not(:disabled):not(.disabled).active {
  color: $primary;
  background-color: $white;
  border-color: #021f62;
}

@media (max-width: 1368px) {
  .btn-red_circle {
    font-size: $text-header;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 991.98px) {
  .btn-red_circle {
    font-size: $text-header;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .react-datepicker-wrapper {
    width: inherit;
  }
}
