@import 'src/scss/variables';
@import 'src/scss/colors';
@import 'src/scss/fonts';
@import 'src/scss/margins';

.navbar {
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  padding: 0px 24px;
  height: 8vh;
  background-color: $white;

  .logoSura {
    background-size: 100%, cover, cover, contain;
    height: 41px;
    width: 80px;
    margin-top: 6px;
    background-image: url('../../../../public/assets/img_logo_general.svg');
    // background-image: url("#{$homepage}../../../../../../public/assets/img_logo_general.svg");
    // background-image: url("#{$homepage}/assets/img_logo_general.svg");
    background-repeat: no-repeat;
    background-position-y: center;
  }

  .userMenu {
    button {
      font-size: 18px;
      color: $primary !important;
      background-color: initial !important;
      border: none;
      min-width: 100px;
      padding: 0 !important;

      &::after {
        display: none !important;
      }

      &:active,
      &:focus {
        border-style: none !important;
        border: 0px !important;
        outline: 0 !important;
        box-shadow: none !important;
      }
    }

    .header {
      line-height: 30px;

      .userThumbnail {
        float: left;
        margin-right: 4px;
        border: 1px solid #afafaf;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        width: 30px;
        height: 30px;
        text-align: center;

        img {
          height: 20px;
          margin-top: -6px;
          margin-left: 1px;
        }
      }
    }

    .dropdownMenu {
      margin-top: 4px !important;
      a {
        font-size: 16px;
        svg {
          margin-right: 8px;
        }
      }
    }
  }

  .divider {
    margin-top: 8px;
    height: 20px !important;
  }
  .brandTitle {
    width: 210px;
    .text {
      color: $primary;
      padding-left: 10px;
      font-weight: bold;
    }
  }

  .mainMenu {
    width: 100%;

    .menuItem {
      color: $primary;
      font-size: 14pt;
      margin: 1px 2rem;
      a {
        color: $primary;
        font-size: 14pt;
        &:hover,
        &:active,
        &:focus {
          font-weight: bold !important;
          color: $primary !important;
        }
        &.navLink {
          &:hover,
          &:active,
          &:focus {
            font-weight: bold !important;
            color: $primary !important;
          }
        }
      }
    }

    .dropdownItemMenu {
      color: $primary;
      font-size: 14pt;
      margin: 1px 2rem;

      a {
        color: $primary !important;
        font-size: 14pt;
        background-color: $white;
        margin: 0;
        &:hover,
        &:active,
        &:focus {
          font-weight: bold;
          color: $primary !important;
        }
      }

      div {
        border: none;
        border-top: 1px solid #e2e2e2b0;
        -webkit-box-shadow: 0px 3px 4px 0px rgba(102, 102, 102, 0.69);
        -moz-box-shadow: 0px 3px 4px 0px rgba(102, 102, 102, 0.69);
        box-shadow: 0px 3px 4px 0px rgba(102, 102, 102, 0.69);
        a {
          border-bottom: 1px solid #e2e2e2 !important;
          margin-left: 10px !important;
          margin-right: 10px !important;
          width: inherit;

          &:last-child {
            border-bottom: none !important;
          }
        }
      }

      .dropdownItem {
        color: $abbey !important;
      }
    }

    a {
      color: $primary !important;
      &:hover,
      &:active,
      &:focus,
      &.active {
        font-weight: bold;
        color: $primary !important;
      }
    }
  }
}

.mobileNavbar {
  padding: 0;
  a {
    border: none !important;
    border-bottom: 1px solid #e2e2e2 !important;
    text-align: right;
    &:last-child {
      border-bottom: none !important;
    }
  }

  div {
    border: none !important;
    text-align: right;
    a {
      padding: 0.5rem 0 !important;
    }
    &:last-child {
      border-bottom: none !important;
    }
  }

  .navbarNav {
    width: 100%;
    flex-direction: column !important;
  }
}

@media (max-width: $breakpoint-tablet) {
  .navbar {
    display: none;
  }
}

@media (max-width: 980px) {
  .navbar {
    .mainMenu {
      .menuItem {
        margin: 1px 0rem;
      }

      .dropdownItemMenu {
        margin: 1px 0rem;
      }
    }
  }
}
