@import 'colors';
@import 'fonts';
@import 'margins';
@import 'variables';

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input,
span,
a,
button,
select,
textarea {
  color: #4d4d4d;
}

input::placeholder {
  font-size: 16px;
  color: #4d4d4d;
}

.tooltip {
  .css-jhnu6x {
    width: 150px;
  }
}

.base-container {
  left: 50%;
  max-height: 400px;
  max-width: 1190px;
  padding: 0 50px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.base-title {
  color: $pacific-blue;
  font-size: $text-big;
  font-weight: bold;
  line-height: $text-big;
}

.base-subtitle {
  color: $smalt;
  font-size: $text-medium;
  letter-spacing: 0.4px;
  line-height: $text-medium;
}

.base-title-blue {
  color: $primary;
  font-size: $text-big-black;
  font-weight: bold;
  line-height: $text-big-black;
}

.base-content {
  color: $dusty-gray;
  font-size: $text-small;
  text-align: center;
}

.base-content-onboarding {
  padding: 0 20px;
  text-align: center;
}

.base-text-onboarding {
  color: $dusty-gray;
  font-size: $text-header;
  line-height: $text-header;
}

.base-background {
  background-color: $white;
  background-size: auto;
  position: relative;
}

.form-status {
  color: $torch-red;
  display: block;
  font-size: $text-small-query;
  max-width: 500px;
  text-align: center;
}

.resend {
  color: $pelorous;
}

.base-select-title {
  color: $abbey;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}

.base-select-option {
  color: $abbey;
  font-size: 16px;
  font-weight: 900;
  line-height: 18px;
}

.base-nick {
  color: $primary;
  font-size: 22px;
}

@media only screen and (max-width: $breakpoint-desktop-medium) {
  .base-container {
    max-height: 350px;
    max-width: 1000px;
  }

  .base-title {
    font-size: $text-medium-big;
    line-height: $text-medium-big;
  }

  .base-subtitle {
    font-size: $text-medium-black;
    line-height: $text-medium-black;
  }

  .base-title-blue {
    font-size: $text-big-black-query;
    line-height: $text-big-black-query;
  }

  .base-content {
    font-size: $text-small-query;
  }
}

@media only screen and (max-width: $breakpoint-ipad-pro) {
  .base-content-onboarding {
    padding: 0 25px;
  }

  .base-text-onboarding {
    font-size: $text-small;
  }
}

@mixin config-opacity-animation($time, $delay) {
  animation: opacity-animation $time ease-in $delay forwards;
  opacity: 0;
}

@keyframes opacity-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
