@import 'src/scss/colors.scss';

.modal {
  .modalHeader {
    border-bottom: none;
    padding-bottom: 0;
    .modalTitle {
      font-size: 20px;
      color: $primary-blue;
      font-weight: bold;
    }
    span {
      font-size: 36px;
    }
  }

  .modalBody {
    font-size: 16px;
    color: #666666;
    line-height: 20px;
  }
  .modalFooter {
    border-top: none;
    padding-top: 0;
    .cancelButton:not(:disabled):not(.disabled):active:focus,
    .acceptButton:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }

    .acceptButton,
    .acceptButton:not(:disabled):not(.disabled):active {
      font-size: 18px;
      color: $primary;
      font-weight: bold;
      background-color: white;
      border-color: white;
    }
  }
}

.icon {
  width: 20px;
  color: $primary-blue;
}
.phoneIcon {
  width: 15px;
  margin: 0.5rem 0.75rem 0.5rem 0.6rem;
}
