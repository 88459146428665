@import 'src/scss/base.scss';
@import 'src/scss/margins.scss';

.footer {
  width: 100%;
  height: 6vh; /* Footer height */
}

@media only screen and (max-width: $breakpoint-tablet) {
  .footerMargin {
    margin-bottom: 75px;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .footer {
    height: 60px; /* Footer height */
  }
}
