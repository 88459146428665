$abbey: #53565a;
$alto: #dbdbdb;
$dusty-gray: #4d4d4d;
$pacific-blue: #00aec7;
$smalt: #0039a0;
$sunflower: #e3e829;
$white: #fff;
$alabaster: #f8f8f8;
$silver: #7c7777;
$mine-shaft: #fafafa;
$black: #000;
$box-shadow-color: rgba(194, 183, 194, 0.7);
$box-shadow-color-cards: rgba(0, 0, 0, 0.08);
$seashell: #f1f1f1;
$modal-overlay: rgba(0, 57, 160, 0.9);
$engine-modal-overlay: rgba(0, 0, 0, 0.6);
$box-shadow-card: rgba(0, 0, 0, 0.1);
$box-shadow-card-bold: rgba(0, 0, 0, 0.5);
$box-shadow-color-blue: rgba(0, 57, 160, 0.3);
$box-shadow-categories: rgba(0, 0, 0, 0.3);
$box-shadow-purpose: rgba(145, 145, 145, 0.5);
$box-shadow-purpose-base: rgba(145, 145, 145, 0.1);
$box-shadow-purpose-before: rgba(145, 145, 145, 0.1);
$sky-blue: #6ac4ea;
$picton-blue: #39b5e6;
$cerulean: #0299d4;
$deep-cerulea: #0080b3;
$torch-red: #ee0034;
$web-orange: #eea900;
$harlequin: #37ee00;
$half-baked: #7bafd4;
$denim: #0d54d9;
$lima: #75bd1d;
$supernova: #fcce00;
$sunglo: #e36768;
$dove-gray: #6a6a6a;
$gray-sura: #faf6f6;
$lavender: #ad7ce1;
$seagull: #6eb3e4;
$sulu: #c5e86c;
$persian-green: #00b2a9;
$sushi: #79b747;
$pelorous: #4dacc4;
$fun-blue: #146ca4;
$hippie-blue: #589bb7;
$dodger-blue: #4abfff;
$fantasy: #fcf6f6;
$jelly-bean: #237da0;
$dusty-green: #0c5351;

$active-accordion: #64ccc9;
$complete-accordion: #1eb2a0;
$default-accordion: #022b52;
$select: #8299d0;

$primary: #02226B;
$primary-blue: #26328C;
$disable-text: #cbcbcb;
$box-purpose: #3f3f41;
$trash: #fd9292;
$trash-background: #ffe5e5;

$theme-colors: (
  'primary': #0432a0
);
$sublegend: #64ccc9;
$link: #1ba3e0;
$warning-background: #fcf0d5;
$warning: #ff9a41;

$circleOne: #FF4DA2;
$circleTwo: #8673EC;
$circleThree: #D7DE17;
$circleFour: #64D991;
$circleFive: #FE6563;
