@import 'src/scss/colors.scss';

.modal {
  .modalHeader {
    border-bottom: none;
    .modalTitle {
      font-size: 20px;
      color: #333333;
      font-weight: bold;
    }
    span {
      font-size: 36px;
    }
    :global {
      .close {
        padding-top: 0px;
        padding-bottom: 8px;
      }
    }
  }

  .modalBody {
    font-size: 16px;
    color: #666666;
  }
  .modalFooter {
    border-top: none;

    .cancelButton,
    .cancelButton:not(:disabled):not(.disabled):active {
      font-size: 18px;
      color: #333333;
      font-weight: bold;
      background-color: white;
      border-color: white;
    }

    .cancelButton:not(:disabled):not(.disabled):active:focus,
    .acceptButton:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }

    .acceptButton,
    .acceptButton:not(:disabled):not(.disabled):active {
      font-size: 18px;
      color: $primary;
      font-weight: bold;
      background-color: white;
      border-color: white;
    }
  }
}
