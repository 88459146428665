@import 'src/scss/colors';
@import 'src/scss/fonts';
@import 'src/scss/margins';

.floatingButton {
  overflow: hidden;
  right: 0;
  padding: 0;
  margin: 0.4rem 0;
  z-index: 99999999;
  border: 0;
  height: 50px;
  transition: height 0.25s ease-in-out;
  text-align: end;
  display: grid;
  -webkit-border-radius: 10px;
  -webkit-border-top-right-radius: 0px;
  -webkit-border-bottom-right-radius: 0px;
  -moz-border-radius: 10px;
  -moz-border-radius-topright: 0px;
  -moz-border-radius-bottomright: 0px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  &.whatsapp {
    background-color: #6abc41 !important;
    bottom: 248px;
    position: absolute;
    .content {
      span {
        display: none;
        top: 0;
        position: static;
      }
    }
  }

  &.helpUsToImprove {
    background-color: #57b3a0 !important;
    top: 154px;
    position: sticky;
  }

  &.whatsapp:hover {
    height: 144px;
    .content {
      span {
        display: inline-block;
        top: 0;
        position: static;
      }
      img {
        bottom: 0;
        position: absolute;
        z-index: 99;
      }
    }
  }

  &.helpUsToImprove:hover {
    height: 200px;
  }

  &:hover {
    .content {
      img {
        transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
      }
    }
  }
}

.content {
  position: relative;
  width: 60px;
  height: 140px;
  padding: 6px 10px;
  display: inline-block;

  img {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -ms-transition: -ms-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
  }

  span {
    color: $white;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    width: 150px;
    height: 150px;
    font-size: 16px;
    margin-top: 15px;
    padding-top: 7px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
}

@media only screen and (max-width: 769px) {
  .helpUsToImprove {
    display: none !important;
  }
}

@media (max-width: $breakpoint-tablet) {
  .floatinButton {
    .content {
      display: none;
    }

    &.whatsapp {
      position: fixed;
      top: initial !important;
      bottom: 86px;
      right: 26px;
      height: 60px;
      border-radius: 200px 200px 200px 200px;
      -moz-border-radius: 200px 200px 200px 200px;
      -webkit-border-radius: 200px 200px 200px 200px;
      border: 0px solid #000000;
      z-index: 77777;

      .content {
        display: inline-block;
        margin-top: 5px;
      }
    }

    &.whatsapp:hover {
      height: 156px;
    }

    &.helpUsToImprove {
      display: none;
    }

    &.videoScreen {
      left: 40px;
      right: auto;
      bottom: 70px;
    }
  }
}
